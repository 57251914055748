<template>
  <div class="truck-type-wraper text_muted d-flex">
    <div class="parent-type-list">
      <div
        class="parent-type fw-500 py-16 lh-24 pl-16"
        :class="{ isActive: index === currentIndex }"
        v-for="(item, index) in columns"
        :key="item.name"
      >
        <div class="lh-56 flex items-center" @click="handleChangeParentIndex(index)">
          <Icon :name="`${item.code}${index === currentIndex ? '-on' : ''}`" svgClass="mr-4" />
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="child-type-list">
      <div class="px-16 py-8 d-flex"><Icon name="xiala-1" svgClass="mr-4 xiala-rotate" /> 请选择具体车型</div>
      <div
        class="child-type px-16 font-14 fw-400"
        :class="{ active: value === item.name }"
        v-for="item in columns[currentIndex].secondCarTypes"
        :key="item.name"
        @click="() => handleCheck(item)"
      >
        {{ item.name }}
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, nextTick, watch } from '@vue/composition-api';
export default defineComponent({
  name: 'TruckTypePicker',
  model: {
    props: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: String,
      default: () => ''
    },
    title: {
      type: String,
      default: ''
    },
    columns: {
      type: Array,
      default: () => []
    }
  },
  setup(props, ctx) {
    const currentIndex = ref(0);
    const handleChangeParentIndex = index => {
      currentIndex.value = index;
    };
    const handleCheck = item => {
      ctx.emit('confirm', {
        key: item.code,
        value: item.name
      });
      nextTick(() => {
        // 确保在Store更新完成之后才进行模态框的影藏
        ctx.emit('cancel');
      });
    };
    watch(
      () => props.value,
      name => {
        if (!props.columns?.length || !name) return;
        for (let i = 0; i < props.columns.length; i++) {
          const item = props.columns[i];
          if (item && item.secondCarTypes && item.secondCarTypes.find(it => it.name === name)) {
            currentIndex.value = i;
            return;
          }
        }
      },
      { immediate: true }
    );

    return {
      currentIndex,
      handleCheck,
      handleChangeParentIndex
    };
  }
});
</script>
<style lang="less">
.parent-type {
  width: 1.3rem;
  height: 0.56rem;
  background-color: #f0f1f5;
  &.isActive {
    background: rgba(64, 152, 255, 0.1);
    color: rgba(64, 152, 255, 1);
  }
}
.child-type {
  height: 0.48rem;
  line-height: 0.48rem;
  box-shadow: 0px 0px 0px 0px rgba(188, 188, 188, 0.5) inset;
  border-bottom: 0.5px solid #f1f1f1;
  color: #1d1d1d;
  &.active {
    color: rgba(64, 152, 255, 1);
  }
}
.xiala-rotate {
  font-size: 0.2rem !important;
  transform: rotate(-90deg);
}
.child-type-list,
.parent-type-list {
  height: 100vh;
  overflow: scroll;
}
</style>
